import React from 'react';

const ArrowRightBold = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="29.272"
    height="29.272"
    viewBox="0 0 29.272 29.272"
  >
    <defs>
      <clipPath id="clip-path-for-arrow">
        <path id="Trace_arrow_804" data-name="Trace arrow" d="M0,0H29.272V-29.272H0Z" fill="none" />
      </clipPath>
    </defs>
    <g id="Arrow_right" data-name="Arrow right" transform="translate(0 29.272)">
      <g id="Groupe_arrow_1024" data-name="Group arrow 1024" clipPath="url(#clip-path-for-arrow)">
        <g id="Groupe_arrow_1022" data-name="Group arrow 1022" transform="translate(14.636)">
          <path
            id="Trace_arrow_802"
            data-name="Trace arrow 802"
            d="M0,0A14.636,14.636,0,0,0,14.636-14.636,14.635,14.635,0,0,0,0-29.271,14.635,14.635,0,0,0-14.636-14.636,14.636,14.636,0,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_arrow_1023" data-name="Group arrow 1023" transform="translate(13.16 -21.604)">
          <path
            id="Trace_arrow_803"
            data-name="Trace arrow 803"
            d="M0,0H0A1.965,1.965,0,0,0-1.3,3.438L1.032,5.495a1.964,1.964,0,0,1,0,2.947L-1.3,10.5A1.965,1.965,0,0,0,0,13.937a1.963,1.963,0,0,0,1.3-.491l5.673-5a1.964,1.964,0,0,0,0-2.947L1.3.491A1.968,1.968,0,0,0,0,0"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowRightBold;
